module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-53HCBLK","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            originalLocation: document.location.protocol + \"//\" + document.location.hostname + document.location.pathname + document.location.search\n          }\n        }"},"gtmAuth":"3Xv1R7MDAW9a0auk96qIpA","gtmPreview":"env-9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToTheme":"src/views/theme.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
