import { createMuiTheme } from '@material-ui/core/styles';

const primary = '#0086B4';
const secondary = '#5A5A5A';

export default createMuiTheme({
    palette: {
        primary: {
            main: primary
        },
        secondary: {
            main: secondary
        }
    },
    typography: {
        button: {
            textTransform: 'capitalize',
            fontSize: '1.125rem'
        },
        subtitle1 : {
            fontSize: 20,
            fontWeight: 900,
            lineHeight: 'normal',
            letterSpacing: 'normal',
        },
        subtitle2 : {
            fontSize: 20,
            fontWeight: 900,
            lineHeight: 'normal',
            letterSpacing: 'normal',
            '@media (max-width:600px)': {
                fontSize: 16,
            },
        },
        h4 : {
            fontSize: 16,
            '@media (min-width:600px)': {
                fontSize: 20,
            },
        },
        body1: {
            fontSize: 16,
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        body2: {
            fontSize: 16,
            fontWeight: 900,
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        caption: {
            fontSize: 16,
            lineHeight: 'normal',
            letterSpacing: 'normal',
            '@media (max-width:600px)': {
                fontSize: 14,
                fontWeight: 300,
            },
        }
    }
});